.footer {
    background-color: #1f1f1f;
    color: #fff;
  }
  
  .footer-logo {
    margin-bottom: 20px;
  }
  
  .footer ul {
    padding: 0;
  }
  
  .footer ul li {
    list-style: none;
    margin-bottom: 10px;
  }
  
  .footer ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer ul li a:hover {
    color: #8FB93E;
  }
  
  .footer h5 {
    color: #8FB93E;
    margin-bottom: 20px;
  }
  
  .payment-methods img {
    max-width: 50px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .text-center p {
    margin: 0;
    color: #999;
  }
  