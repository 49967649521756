.review-section {
  background-color:#000321;
  padding: 20px 0;
}

.main-review-card {
  background-color: #AFD89D;
  border-radius: 15px;
  padding: 20px;
  position: relative;
  color: #000321;
}



.quote-icon {
  background-color: #8FB93E;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  top: -50px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 70px;
  color: #000321;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}

.review-card2 {
    border-radius: 15px;
    overflow: hidden;
    border: none;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }
  
  .card-image-wrapper {
    position: relative;
  }
  
  .review-card img {
    height: 200px;
    object-fit: cover;
    width: 100%;
  }
  
  .small-quote-icon {
    background-color: #8FB93E;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    top: -25px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 45px;
    color: #000321;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
  }

  .review-card2 .card-body {
    position: relative;
    padding-top: 30px; /* Espacio para el ícono */
    border-top: 5px solid #8FB93E; /* Border top */
  }